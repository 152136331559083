/* eslint-disable no-restricted-globals */
import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Loader from 'components/Loader'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import LessonBox from 'features/lesson/components/LessonBox'
import * as exploreApiRequest from 'libs/explore-api-request'
import styles from './Lessons.module.scss'

type Props = {
  onEmpty?: () => void
  trainer?: string
} & Component

export default function Lessons({ className, onEmpty, trainer }: Props) {
  const location = useLocation()
  const [isLoading, setIsLoading] = React.useState(true)
  const [lessons, setLessons] = React.useState([])

  React.useEffect(() => {
    async function getLessons() {
      const response = await exploreApiRequest.getLessons({
        filter: {
          trainer,
        },
      })

      setIsLoading(false)

      const { data } = response

      if (_.isEmpty(data)) {
        if (onEmpty) onEmpty()
        return
      }

      setLessons(data.slice(0, 6))
    }
    getLessons()
  }, [onEmpty, trainer])

  function handleLessonClick(lessonId: string) {
    navigate(`${paths.LESSON}${lessonId}#explore`, {
      state: {
        explorePage: true,
        from: location.pathname,
      },
    })
  }

  return (
    <div className={classNames('Lessons', className)}>
      {isLoading || _.isEmpty(lessons) ? (
        <Loader overlay={false} />
      ) : (
        <div className={styles.grid}>
          {lessons.map((item) => (
            <LessonBox data={item} key={item.id} onClick={() => handleLessonClick(item.id)} />
          ))}
        </div>
      )}
    </div>
  )
}
